import { default as _91id_93OCQlQG7hwSMeta } from "/var/www/sites/admin.pos2.halifax.dk/releases/20241205132255/pages/connection-drivers/[id].vue?macro=true";
import { default as indexEymqkz7H93Meta } from "/var/www/sites/admin.pos2.halifax.dk/releases/20241205132255/pages/connection-drivers/index.vue?macro=true";
import { default as indexSmyrFk8EXWMeta } from "/var/www/sites/admin.pos2.halifax.dk/releases/20241205132255/pages/index.vue?macro=true";
import { default as index8tR47axkATMeta } from "/var/www/sites/admin.pos2.halifax.dk/releases/20241205132255/pages/merchants/[id]/index.vue?macro=true";
import { default as merchant_45accessesZLsQOPiv3yMeta } from "/var/www/sites/admin.pos2.halifax.dk/releases/20241205132255/pages/merchants/[id]/merchant-accesses.vue?macro=true";
import { default as storesf8GH1QsIRtMeta } from "/var/www/sites/admin.pos2.halifax.dk/releases/20241205132255/pages/merchants/[id]/stores.vue?macro=true";
import { default as terminalsGEa2RzvHxRMeta } from "/var/www/sites/admin.pos2.halifax.dk/releases/20241205132255/pages/merchants/[id]/terminals.vue?macro=true";
import { default as _91id_93ri009YpTuAMeta } from "/var/www/sites/admin.pos2.halifax.dk/releases/20241205132255/pages/merchants/[id].vue?macro=true";
import { default as indexgZPvpEujiLMeta } from "/var/www/sites/admin.pos2.halifax.dk/releases/20241205132255/pages/merchants/index.vue?macro=true";
import { default as _91id_93pdwWpOW9veMeta } from "/var/www/sites/admin.pos2.halifax.dk/releases/20241205132255/pages/users/[id].vue?macro=true";
import { default as index6retq3XRteMeta } from "/var/www/sites/admin.pos2.halifax.dk/releases/20241205132255/pages/users/index.vue?macro=true";
export default [
  {
    name: "connection-drivers-id",
    path: "/connection-drivers/:id()",
    component: () => import("/var/www/sites/admin.pos2.halifax.dk/releases/20241205132255/pages/connection-drivers/[id].vue")
  },
  {
    name: "connection-drivers",
    path: "/connection-drivers",
    component: () => import("/var/www/sites/admin.pos2.halifax.dk/releases/20241205132255/pages/connection-drivers/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/sites/admin.pos2.halifax.dk/releases/20241205132255/pages/index.vue")
  },
  {
    name: _91id_93ri009YpTuAMeta?.name,
    path: "/merchants/:id()",
    component: () => import("/var/www/sites/admin.pos2.halifax.dk/releases/20241205132255/pages/merchants/[id].vue"),
    children: [
  {
    name: "merchants-id",
    path: "",
    component: () => import("/var/www/sites/admin.pos2.halifax.dk/releases/20241205132255/pages/merchants/[id]/index.vue")
  },
  {
    name: "merchants-id-merchant-accesses",
    path: "merchant-accesses",
    component: () => import("/var/www/sites/admin.pos2.halifax.dk/releases/20241205132255/pages/merchants/[id]/merchant-accesses.vue")
  },
  {
    name: "merchants-id-stores",
    path: "stores",
    component: () => import("/var/www/sites/admin.pos2.halifax.dk/releases/20241205132255/pages/merchants/[id]/stores.vue")
  },
  {
    name: "merchants-id-terminals",
    path: "terminals",
    component: () => import("/var/www/sites/admin.pos2.halifax.dk/releases/20241205132255/pages/merchants/[id]/terminals.vue")
  }
]
  },
  {
    name: "merchants",
    path: "/merchants",
    component: () => import("/var/www/sites/admin.pos2.halifax.dk/releases/20241205132255/pages/merchants/index.vue")
  },
  {
    name: "users-id",
    path: "/users/:id()",
    component: () => import("/var/www/sites/admin.pos2.halifax.dk/releases/20241205132255/pages/users/[id].vue")
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/var/www/sites/admin.pos2.halifax.dk/releases/20241205132255/pages/users/index.vue")
  }
]